import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {AdminLayoutComponent} from './layout/admin-layout/admin-layout.component';
import {BasicLayoutComponent} from './layout/basic/basic-layout.component';
import { FormEdhafiComponent } from './layout/form-edhafi/form-edhafi.component';
import {NoPermissionComponent} from './shared/components/no-permission/no-permission.component';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  { path: '', 
    component:  FormEdhafiComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
        import('./features/assessor-form/assessor-form.module').then(m => m.AssessorFormModule),
      },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'UserManagement',
        loadChildren: () =>
          import('./features/user-management/user-managment.module').then(
            (m) => m.UserManagementModule
          ),
      },
    
      {path: 'no-permission', component: NoPermissionComponent},
    ],
  },
  {path: '**', component: PageNotFoundComponent},
  { path: 'features/assessor-form', loadChildren: () => import('./features/assessor-form/assessor-form.module').then(m => m.AssessorFormModule) },
  
  // otherwise redirect to home
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
