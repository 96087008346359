import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssessorFormService {

  constructor(private apiService: ApiService) { }


  getCitys(token) {
    return this.apiService
      .get(`${environment.apiUrl}/api/countries/`+token)
      .pipe(catchError(this.handleError));
  }
  getLanguages() {
    return this.apiService
      .get(`${environment.apiUrl}/api/languages`)
      .pipe(catchError(this.handleError));
  }
  handleError(error) {
    return throwError(error);
  }

  addCV(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_cv`, modal)
      .pipe(catchError(this.handleError));
  }
  upload_file(files) {
    const formData = new FormData();
    if (files && files.length) {
      files.forEach(file => {
        formData.append('cv_file', file, file.name);
      });
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/edhafi-upload-cv`, formData)
      .pipe(catchError(this.handleError));
  }
  registerUser(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/register-edhafi`,model)
      .pipe(catchError(this.handleError));
  }
  completeProfileUser(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/complete-register`,model)
      .pipe(catchError(this.handleError));
  }
  sectors(){
    return this.apiService
      .get(`${environment.apiUrl}/api/sectors`)
      .pipe(catchError(this.handleError));
  }
  getBaiscInfo(token){
    return this.apiService
      .get(`${environment.apiUrl}/api/auth/basic-info/`+token)
      .pipe(catchError(this.handleError));
  }
}
