<div class="container-fluid p-5 text-center role-reassign">
  <img alt="warning" src="./assets/images/warning-illustration.svg"/>
  <div class="title">Are you sure?</div>
  <div class="info">
    Selected user is already assigned as
    <span class="highlight-text">{{ selectedUser.role_name_have }}</span>
    and by assigning this user to this role, the
    user won’t be
    <span class="highlight-text">
   {{ selectedUser.role_name_have }}
    </span>
    and will be
    <span class="highlight-text">
      {{roleName}}
    </span>
  </div>
  <div class="action">
    <button
      (click)="submitAction(false)"
      class="mat_orange-outline mr-2 confirmation-btns"
      mat-raised-button
    >
      <span>No, Go Back Please</span>
    </button>
    <button
      (click)="submitAction(true)"
      class="mat_orange ml-2 confirmation-btns"
      mat-raised-button
    >
      <span>Yes, Confirm Assignment</span>
    </button>
  </div>
</div>
