<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
  <button *ngIf="renderBtn" class="attach-btn" mat-icon-button>
    <img alt="" src="./assets/images/icons/attachment-icon.svg"/>
    <input
      #fileDropRef
      (change)="fileBrowseHandler($event)"
      [accept]="allowedFiles"
      [attr.id]="'fileDropRef_' + managerIndex"
      [disabled]="Disapled"
      multiple
      type="file"
    />
  </button>
  <div
    (fileDropped)="onFileDropped($event)"
    *ngIf="!renderBtn"
    [class.loading]="loading"
    [hidden]="!docsEditable"
    appDnd
    class="dnd-container"
  >
    <input
      #fileDropRef
      (change)="fileBrowseHandler($event)"
      [accept]="allowedFiles"
      [attr.id]="'fileDropRef_' + managerIndex"
      [disabled]="Disapled"
      multiple
      type="file"
    />
    <div>
      <svg
        height="25"
        viewBox="0 0 19 25"
        width="19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11362.336-8571a2.322,2.322,0,0,1-2.336-2.3v-15.742h4.725a2.321,2.321,0,0,0,2.335-2.3V-8596h9.605a2.317,2.317,0,0,1,2.335,2.3v20.4a2.317,2.317,0,0,1-2.335,2.3Zm6.421-5.439a.738.738,0,0,0,.743.732.738.738,0,0,0,.743-.732v-5.292l1.916,1.887a.75.75,0,0,0,1.051,0,.723.723,0,0,0,0-1.035l-3.185-3.138a.749.749,0,0,0-1.05,0l-3.185,3.138a.725.725,0,0,0,0,1.035.75.75,0,0,0,1.051,0l1.915-1.887Zm-3.184-10.2a.738.738,0,0,0,.743.732h6.368a.738.738,0,0,0,.743-.732.737.737,0,0,0-.743-.732h-6.368A.738.738,0,0,0,11365.573-8586.638Zm-5.573-3.871v-.052a.729.729,0,0,1,.219-.518l4.776-4.707a.747.747,0,0,1,.524-.215h.054v4.654a.844.844,0,0,1-.849.837Z"
          fill="#e48d1a"
          id="upload-icon"
          transform="translate(-11360 8596)"
        />
      </svg>
    </div>
    <h4>{{ label ? label : 'Drag and drop' }}</h4>
    <div>
      <span>{{ label ? 'Or' : 'Supporting JPG, PNG here Or' }}</span>
      <label [attr.for]="'fileDropRef_' + managerIndex"
      >Browse Your Files</label
      >
    </div>
    <!--
    <div class="hint">
      {{ 'forms.SupportsFiles' | translate }}
    </div> -->
  </div>
  <!-- <div class="hint-listing">
        <div [ngClass]="{'has-error': selectedfilesCount > maxFiles}">{{ 'forms.Max-files-is' | translate }} {{maxFiles}}</div>
        <!- - <div [ngClass]="{'has-error': !validFile}">{{ 'forms.InvalidFile' | translate }}</div> - ->
        <div [ngClass]="{'has-error': !validFile}">{{ 'forms.AcceptableFiles' | translate }}</div>
        <div [ngClass]="{'has-error': fileMaxSiZeErorr}">{{ 'forms.Max-file-size' | translate }}</div>
        <div [ngClass]="{'has-error': !files.length && isRequired && isSubmited}">{{ 'forms.Required' | translate }}</div>
    </div> -->
  <div *ngIf="selectedfilesCount > maxFiles && maxFiles > 1" class="has-error">
    {{ 'Maximum allowed files is' }} {{ maxFiles }}
  </div>
  <div
    *ngIf="selectedfilesCount > maxFiles && maxFiles === 1"
    class="has-error"
  >
    {{ 'Only One File is allowed' }}
  </div>
  <div *ngIf="!validFile" class="has-error">Accept only Jpg, Png, tiff and bmp format</div>
  <div *ngIf="fileMaxSiZeErorr" class="has-error">Max File Size 10 MB</div>
  <div *ngIf="fileMaxNameSiZeErorr" class="has-error"></div>
  <div *ngIf="!files.length && isRequired && isSubmited" class="has-error">
    Is Required
  </div>
  <div *ngIf="!files.length" [hidden]="docsEditable" class="dnd-container">
    <h4>No Files</h4>
  </div>
  <div class="files-list">
    <div class="row">
      <div
        *ngFor="let url of urls; let i = index"
        [ngClass]="{ 'col-md-6': cols == 2 }"
        class="col-4"
      >
        <div *ngIf="url" class="file-item">
          <img [src]="url" alt="" class="img-photo-manager"/>
          <div (click)="deleteFile(i, url.url)" class="photo-file-btn">
            <svg
              height="10"
              viewBox="0 0 10 10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.982,20.125l-3.857-3.857-3.857,3.857-1.143-1.143,3.857-3.857-3.857-3.857,1.143-1.143,3.857,3.857,3.857-3.857,1.143,1.143-3.857,3.857,3.857,3.857Z"
                data-name="Path 14998"
                fill="#959595"
                id="Path_14998"
                transform="translate(-10.125 -10.125)"
              />
            </svg>
            <!-- <button
              class="btn-delete"
              (click)="deleteFile(i, file.attachmentId)"
            >
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  id="Union_4"
                  data-name="Union 4"
                  d="M0,10A9.938,9.938,0,0,1,10,0,9.938,9.938,0,0,1,20,10,9.938,9.938,0,0,1,10,20,9.938,9.938,0,0,1,0,10Zm1.429,0A8.571,8.571,0,1,0,10,1.429,8.6,8.6,0,0,0,1.429,10ZM10,11.143,6.143,15,5,13.857,8.857,10,5,6.143,6.143,5,10,8.857,13.857,5,15,6.143,11.143,10,15,13.857,13.857,15Z"
                  fill="#959595"
                />
              </svg>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
