import {Injectable} from '@angular/core';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';
import {ApiService} from 'src/app/core/service/api.service';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private apiService: ApiService,
              private datePipe: DatePipe) {
  }

  getPaymentDetailsByToken(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/training_payment_details`, model)
      .pipe(catchError(this.handleError));
  }

  paymentAction(model, apiLink) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/${apiLink}`, model)
      .pipe(catchError(this.handleError));
  }

  documentDownloader(model) {
    return this.apiService
      .getDownload(`${environment.apiUrl}/api/system/download_files`, model)
      .pipe(catchError(this.handleError));
  }

  convertUnixDate(date) {
    const fullDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    console.log((fullDate));
    return fullDate;
  }

  handleError(error) {
    return throwError(error);
  }
}
