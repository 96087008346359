<div class="container-fluid resend-container p-0 text-center">
  <div (click)="onClose()" cdkFocusInitial class="close-dialog">
    <mat-icon>close</mat-icon>
  </div>
  <div class="img-container"></div>
  <div class="title">Check your inbox!</div>
  <div class="info">
    Account created successfully,<br/>
    kindly check your email to verify your account
  </div>
  <button
    (click)="onResendMail()"
    [class.loading]="loading"
    [disabled]="loading"
    class="resend-btn"
    mat-button
  >
    <img
      alt=""
      src="../../../../assets/images/resend-verification-mail-icon.svg"
    />
    <span>Resend Verification Mail</span>
  </button>
</div>
