<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
  <div class="row">
    <div
      *ngFor="let file of files; let i = index"
      [ngClass]="{ 'col-md-6': cols == 2 }"
      class="col-12"
    >
      <div class="file-item">
        <div class="file-details">
          <div class="file-icon">
            <img
              *ngIf="fileType(file.name) == 'doc'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'jpg'"
              src="./assets/images/file-icons/file-jpg-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'pdf'"
              src="./assets/images/file-icons/file-pdf-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'png'"
              src="./assets/images/file-icons/file-png-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'xls'"
              src="./assets/images/file-icons/file-xls-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'zip'"
              src="./assets/images/file-icons/file-zip-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'pptx'"
              src="./assets/images/file-icons/file-ppt-icon.svg"
            />
            <!--  -->
            <img
              *ngIf="fileType(file.name) == 'gif'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'tiff'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'bmp'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
          </div>
          <div>
            <label [title]="file?.name">{{ file?.name }}</label>
            <span>{{ formatBytes(file?.size) }}</span>
            <!-- <span>{{ file.size }}</span> -->
          </div>
        </div>
        <div class="file-btn">
          <a
            class="download"
            (click)="onDocumentDownloader(file.download, file.name)"
            *ngIf="!downloadWithApi"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20.001"
              viewBox="0 0 20 20.001"
            >
              <path
                id="download"
                d="M2.4,20A2.506,2.506,0,0,1,0,17.507V11.418a.3.3,0,0,1,.093-.2l3.07-5.917a.435.435,0,0,1,.418-.22H5.349a.49.49,0,0,1,0,.978H3.86L1.3,10.954h2.6A3.041,3.041,0,0,1,6.7,12.837a2.157,2.157,0,0,0,1.953,1.3h2.7a2.156,2.156,0,0,0,1.953-1.3,3.042,3.042,0,0,1,2.791-1.883h2.651l-2.163-4.89H14.651a.49.49,0,0,1,0-.978h2.233a.461.461,0,0,1,.442.245l2.6,5.892a.467.467,0,0,1,.069.2v6.088A2.506,2.506,0,0,1,17.6,20ZM9.674,11.32,6.512,7.971a.509.509,0,0,1,0-.587.451.451,0,0,1,.652-.1L9.535,9.78V.49a.466.466,0,1,1,.931,0V9.78l2.372-2.494a.447.447,0,0,1,.558,0,.506.506,0,0,1,.093.685L10.326,11.32l-.027.029a.428.428,0,0,1-.625-.029Z"
                fill="#b54150"
              />
            </svg>
          </a>
          <a
            class="download"
            (click)="download(file.tokenDownload, file.name)"
            *ngIf="downloadWithApi"
          >
            <svg
              height="20.001"
              viewBox="0 0 20 20.001"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.4,20A2.506,2.506,0,0,1,0,17.507V11.418a.3.3,0,0,1,.093-.2l3.07-5.917a.435.435,0,0,1,.418-.22H5.349a.49.49,0,0,1,0,.978H3.86L1.3,10.954h2.6A3.041,3.041,0,0,1,6.7,12.837a2.157,2.157,0,0,0,1.953,1.3h2.7a2.156,2.156,0,0,0,1.953-1.3,3.042,3.042,0,0,1,2.791-1.883h2.651l-2.163-4.89H14.651a.49.49,0,0,1,0-.978h2.233a.461.461,0,0,1,.442.245l2.6,5.892a.467.467,0,0,1,.069.2v6.088A2.506,2.506,0,0,1,17.6,20ZM9.674,11.32,6.512,7.971a.509.509,0,0,1,0-.587.451.451,0,0,1,.652-.1L9.535,9.78V.49a.466.466,0,1,1,.931,0V9.78l2.372-2.494a.447.447,0,0,1,.558,0,.506.506,0,0,1,.093.685L10.326,11.32l-.027.029a.428.428,0,0,1-.625-.029Z"
                fill="#b54150"
                id="download"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
