<div class="dashBoard main-wrapper">
  <app-sidebar
    (menuLoading)="menuLoading = false"
    (sidebarTrigger)="toggleSide = false"
    [toggleSide]="toggleSide"
    class="side-menu"
  ></app-sidebar>
  <div class="main-container container-fluid p-0">
    <app-header (sidebarTrigger)="toggleSide = true"></app-header>
    <div *ngIf="menuLoading" class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div class="container-fluid p-0">
      <div class="main-content">
        <div *ngIf="!menuLoading" id="content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
